import React, { useState, useEffect } from 'react';
import { FaPlus, FaMinus, FaTrash } from 'react-icons/fa';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UnderHeader from '../components/UnderHeader';
import { updateCartItemQuantity, removeItemFromCart } from '../utils/cartUtils';
import './CartPage.css';
import { useCurrency } from '../contexts/CurrencyContext';

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartId, setCartId] = useState(null);
  const { currency } = useCurrency();

  const calculateTotalPrice = (items) => {
    if (!items || items.length === 0) {
      setTotalPrice(0);
      return;
    }
    const total = items.reduce(
      (sum, item) => sum + parseFloat(item[`price_${currency.toLowerCase()}`]) * item.quantityInCart,
      0
    );
    setTotalPrice(total);
  };

  const fetchCart = () => {
    fetch('https://bufferka.shop/cart/view', {
      method: 'GET',
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        if (data && data.items) {
          setCartItems(data.items || []);
          calculateTotalPrice(data.items || []);
          setCartId(data.cartId);
        }
      })
      .catch(error => console.error('Error fetching cart:', error));
  };

  useEffect(() => {
    fetchCart();
  }, [currency]);

  const handleIncrement = (productId) => {
    updateCartItemQuantity(productId, true)
      .then(() => {
        fetchCart();
      })
      .catch(error => console.error('Error incrementing quantity:', error));
  };

  const handleDecrement = (productId) => {
    updateCartItemQuantity(productId, false)
      .then(() => {
        fetchCart();
      })
      .catch(error => console.error('Error decrementing quantity:', error));
  };

  const handleRemove = (productId) => {
    removeItemFromCart(productId)
      .then(() => {
        fetchCart();
      })
      .catch(error => console.error('Error removing item:', error));
  };

  // Добавляем функцию для инициализации оплаты
  const initiatePayment = () => {
    if (!cartId) {  // Проверка, если cartId не определен
      console.error('cartId не определен');
      return;
    }

    fetch('https://bufferka.shop/purchase/initiate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify({ cartId, totalPrice, currency}),  // Передаем cartId как UUID
    })
      .then(response => response.json())
      .then(data => {
        if (data.paymentUrl) {
          window.location.href = data.paymentUrl;  // Перенаправляем пользователя на страницу оплаты
        }
      })
      .catch(error => console.error('Ошибка при инициализации оплаты:', error));
  };


  return (
    <div className="service-page">
      <Header />
      <UnderHeader title="Корзина"></UnderHeader>
      <div className="cart-content">
        <div className="cart-items">
          {cartItems.length === 0 ? (
            <p>Корзина пуста.</p>
          ) : (
            <ul>
              {cartItems.map((item) => (
                <li key={item.cartItemId} className="cart-item">
                  <div className="item-info">
                    <strong className="item-title">{item.name}</strong>
                    <p className="item-description">{item.description}</p>
                  </div>
                  <p className="item-total-price">{item[`price_${currency.toLowerCase()}`] * item.quantityInCart} {currency}</p>
                  <div className="cart-item-actions">
                    <button onClick={() => handleRemove(item.productId)} className="remove-button">
                      <FaTrash />
                    </button>
                    <div className="quantity-control">
                      <button
                        onClick={() => handleDecrement(item.productId)}
                        disabled={item.quantityInCart === 1}
                        className="quantity-button"
                      >
                        <FaMinus />
                      </button>
                      <span className="quantity">{item.quantityInCart}</span>
                      <button onClick={() => handleIncrement(item.productId)} className="quantity-button">
                        <FaPlus />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        {cartItems.length > 0 && (
  <div className="total-price">Общая стоимость: {totalPrice} {currency}</div>
)}
{cartItems.length > 0 && (
  <button onClick={initiatePayment} className="payment-button">
    Оплатить
  </button>
)}
      </div>
      <Footer />
    </div>
  );
};

export default CartPage;